import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HStack, Link, Text, VStack } from '@chakra-ui/react';

import Loader from '@modules/common-ui/components/global-loader';
import { landingPageStyles } from './styles';
import { getAffiliateProfile } from '@app/modules/core/aws/lambdas/affiliates';
import { IGetAffiliateResponse } from '@app/modules/core/aws/lambdas/affiliates/interfaces';
import { ConditionSeparator } from '@app/modules/common-ui/components/condition-separator';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter';
import { ImgAvatar } from '@app/modules/common-ui/components/profile-basic-info/ImgAvatar';

function AffiliateLandingPage(): ReactElement {
  const { affiliateUserName } = useParams();
  const isLoading = false;
  const [affiliateProfile, setAffiliateProfile] = useState<IGetAffiliateResponse | undefined>();
  const [err, setErr] = useState<string | undefined>();

  useEffect(() => {
    getAffiliateProfile(affiliateUserName as string)
      .then((affiliateData) => {
        setAffiliateProfile(affiliateData);
        // console.log(affiliateData);
      })
      .catch((error) => {
        setErr(error.message);
      });
  }, []);

  return (
    <VStack>
      <UnAuthHeader activeLink='' />
      <HStack h='100px' />
      {isLoading ? (
        <Loader />
      ) : (
        <ConditionSeparator
          condition={!err}
          target={
            <VStack>
              {affiliateProfile?.logo && (
                <Link href={`https://${affiliateProfile?.website}`}>
                  <ImgAvatar file={affiliateProfile!.logo} style={{ borderRadius: '0' }} />
                </Link>
              )}
              <Text {...landingPageStyles.welcomeAdditionalText}>
                {affiliateProfile?.affiliateName ?? '...'}
              </Text>
            </VStack>
          }
          defaultTarget={
            <Text {...landingPageStyles.errorText}>
              There was an error retrieving the affiliate&apos;s content. Please check the URL and
              try again. Error: {err}
            </Text>
          }
        />
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default AffiliateLandingPage;
