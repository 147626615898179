import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { VStack, Center, Text } from '@chakra-ui/react';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { SideBarLocationType } from '@modules/common-ui/components/side-bar/interfaces';
import { APP_ROUTES, WITHOUT_SIDEBAR_ROUTES } from '@modules/core/constants';

import appStoreDownloadIcon from '../../../../assets/images/downloadFromAppStore.svg';

import { appDownloadStyles } from './styles';
import { IIosAppDownloadProps } from './interfaces';

function IosAppDownload(props: IIosAppDownloadProps): ReactElement {
  const { pathname } = useLocation<SideBarLocationType>();

  function includeSideBar(): boolean {
    if ((pathname as String).startsWith(APP_ROUTES.affiliageLandingPageBase)) return false;
    return !WITHOUT_SIDEBAR_ROUTES.includes(pathname);
  }

  return (
    <ConditionSeparator
      condition={includeSideBar()}
      target={
        <VStack
          {...(props.isSideBarExpanded
            ? appDownloadStyles.contentBoxWSidebar
            : appDownloadStyles.contentBoxWOSidebar)}
        >
          <Center {...appDownloadStyles.textContainer}>
            <Text
              {...(props.isSideBarExpanded
                ? appDownloadStyles.textStyleWSideBar
                : appDownloadStyles.textStyleWOSideBar)}
            >
              To take full advantage of LVED, you should download the LVED app from the IOS App
              Store. You may manage your profile and subscription here.
            </Text>
          </Center>
          <VStack {...appDownloadStyles.textContainer}>
            <Center {...appDownloadStyles.textContainer}>
              <a href='https://apps.apple.com/us/app/lved/id6448995985'>
                <img src={appStoreDownloadIcon} alt='Download from the iOS AppStore' />
              </a>
            </Center>
          </VStack>
          {props.children}
        </VStack>
      }
    />
  );
}

export default IosAppDownload;
