import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Button, Flex, VStack } from '@chakra-ui/react';

import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { useAuth, useQuery } from '@modules/core/hooks';
// import { updateUserProfile } from '@app/modules/core/aws/dynamo-db/user-profile';
import { UserTemplateFieldType } from '@app/modules/core/aws/dynamo-db/user-profile/interfaces';
import ProgressMenu from './ProgressMenu';
import Header from '@app/modules/common-ui/components/header';
import {
  AnswerType,
  AnswersType,
} from '@app/modules/core/aws/dynamo-db/my-circle-onboarding/interfaces';
import { NavigateFunction, useNavigate } from 'react-router';
import { APP_ROUTES } from '@app/modules/core/constants';
import { has, isNil, omitBy } from 'lodash';
import { onboardingStyles } from './styles';
import QuestionManager from '@app/modules/common-ui/components/question-manager';
import Modal from '@app/modules/common-ui/components/modal';
import { updateUserProfile } from '@app/modules/core/aws/dynamo-db/user-profile';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  ProfileCompleted,
} from '@app/modules/core/google-analytics/events/signupGAEvent';

const sectionCompletedText = `You've successfully made your Lved account.  Next, we'll help you complete your basic account profile and make sure everything is in order to select your plan.`;
const profileCompletedText = `With your basic account profile completed, we'll now help you select a Lved plan to get everything in order.`;

function ProfileOnboarding(): ReactElement {
  const { user }: IAuthContext = useAuth();
  const { submit, isLoading, res } = useQuery(updateUserProfile);
  const fields: UserTemplateFieldType[] =
    user?.Template.Fields.filter(
      ({ ReadOnly, FieldName }) => !ReadOnly && FieldName !== 'thumbnail'
    ) || [];
  fields.sort((a, b) => {
    if (a.Index > b.Index) return 1;
    if (a.Index < b.Index) return -1;
    return 0;
  });
  const [currentField, setCurrentField] = useState<number | undefined>();

  const navigateTo: NavigateFunction = useNavigate();
  // const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isCongratsModelOpen, setIsCongratsModelOpen] = useState<boolean>(false);
  const [answers, setAnswers] = useState<AnswersType>({});

  // const openModal: () => void = () => setModalOpen(true);
  // const closeModal: () => void = () => setModalOpen(false);
  const navigateToPricing: () => void = () => navigateTo(APP_ROUTES.pricing);
  const resetScroll: () => void = () => window.scrollTo(0, 0);
  let setCountyIfAvailableAndNotSet: (address: { Zip: string }) => void;

  const writeAnswer = (key: string, value: AnswerType): void => {
    if (key === 'address' && value.Zip.length === 5 && value.Zip !== answers.address?.Zip) {
      setCountyIfAvailableAndNotSet(value);
    }
    return setAnswers((oldAnswers) => ({ ...oldAnswers, [key]: value }));
  };

  setCountyIfAvailableAndNotSet = (address: { Zip: string }) => {
    // set county if this was address...
    if (fields[currentField ?? 0].FieldName === 'address') {
      // Look up county and seed it...
      const { Zip } = address;
      // console.log(`Looking up county from zip: ${Zip}`);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${Zip}&sensor=true&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.results?.length) {
            const components = data.results[0].address_components;
            const countyLongName = components.find((item: any) => {
              return (
                item.types.find((type: string) => {
                  return type === 'administrative_area_level_2';
                }) !== undefined
              );
            })?.long_name;
            if (countyLongName) {
              writeAnswer(
                'residenceCounty',
                countyLongName.substring(0, countyLongName.lastIndexOf(' '))
              );
            } else {
              // console.log('Error with County Loookup record: ');
              // console.log(components);
            }
          } else {
            // console.log('Failed lookup');
            // console.log(data);
          }
        });
    }
  };

  const isContinueDisallowed = (): boolean => {
    if (!fields) return true;
    if (fields[currentField ?? 0].isRequired === false) return false;
    return !has(answers, fields[currentField ?? 0].FieldName);
  };

  const canSkip = (): boolean => {
    if (!fields) return true;
    if (fields[currentField ?? 0].isRequired === true) return false;
    return !has(answers, fields[currentField ?? 0].FieldName);
  };

  const showNextQuestion = (): void => {
    if (currentField === undefined) return;
    if (currentField! < fields.length - 1) {
      setCurrentField(currentField + 1);
      resetScroll();
    }
  };

  const showPrevQuestion = (): void => {
    if (currentField) {
      setCurrentField(currentField! - 1);
      resetScroll();
    }
  };

  useEffect(() => {
    if (res) {
      setIsCongratsModelOpen(true);
      TrackGoogleAnalyticsEvent(
        UserCategory,
        ProfileCompleted,
        `email: ${user?.contactInfo?.Email ?? 'unknown'}`
      );
    } else setIsCongratsModelOpen(false);
    setCurrentField(undefined);
  }, [res]);

  return (
    <VStack paddingTop='100px' paddingBottom='130px' margin='10px'>
      <Header />
      {currentField === undefined && (
        <>
          <ProgressMenu
            stage={res ? 2 : 1}
            description={res ? profileCompletedText : sectionCompletedText}
            onNext={() => {
              if (res) navigateToPricing();
              else setCurrentField(0);
            }}
          />

          <Modal
            isOpen={isCongratsModelOpen}
            onClose={() => {
              setIsCongratsModelOpen(false);
            }}
            onSubmit={() => {
              setIsCongratsModelOpen(false);
            }}
            title='Congratulations!'
            submitButtonText='Continue'
            text={`You've successfully completed your basic account profile.`}
          />
        </>
      )}
      {currentField !== undefined && (
        <Flex flexDirection='column' {...onboardingStyles.profileQuestionContainer}>
          <>
            <QuestionManager
              question={fields[currentField ?? 0]}
              answers={answers}
              callback={writeAnswer}
            />

            <Flex
              {...onboardingStyles.buttonBox}
              justifyContent={(currentField ?? 0) > 0 ? 'space-between' : 'flex-end'}
            >
              {(currentField ?? 0) > 0 && (
                <Button onClick={showPrevQuestion} {...onboardingStyles.prevButton}>
                  Back
                </Button>
              )}

              <Flex>
                <Box {...onboardingStyles.continueButtonBox}>
                  {currentField < fields.length - 1 ? (
                    <Button
                      isDisabled={isContinueDisallowed()}
                      onClick={showNextQuestion}
                      {...onboardingStyles.nextButton}
                    >
                      {canSkip() ? 'Skip' : 'Continue'}
                    </Button>
                  ) : (
                    <Button
                      isLoading={isLoading}
                      isDisabled={isContinueDisallowed()}
                      onClick={() => {
                        submit(omitBy(answers, isNil));
                      }}
                      {...onboardingStyles.nextButton}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Flex>
            </Flex>
          </>
        </Flex>
      )}
    </VStack>
  );
}

/*
  <Modal
    isOpen={isModalOpen}
    onClose={closeModal}
    onSubmit={navigateToPricing}
    title='Are you sure you want to skip the completion of your profile?'
    text={`If you skip the remaining questions now, the answers you've entered so far will not
        be saved and you will be redirected to the plan selection page. However, you will be able to
        answer all the questions later.`}
  />
  <Button {...onboardingStyles.skipButton} onClick={openModal}>
    Skip all
  </Button>
*/

export default ProfileOnboarding;
