const UserCategory: string = 'User';
const SignUpCompletedAction: string = 'sign_up';
const ValidatedEmail: string = 'email_validated';
const FirstLogin: string = 'first_time_login';
const ProfileCompleted: string = 'completed_profile';
const SubscribedCompletedAction: string = 'purchase';
const JoinedGroupPlanCompletedAction: string = 'join_group';
const SubscriptionPlanSelected: string = 'subscription_selected';
const AcknowledgedIOSLimitation: string = 'ios_only_acknowledged';

export default UserCategory;
export {
  UserCategory,
  SignUpCompletedAction,
  SubscribedCompletedAction,
  JoinedGroupPlanCompletedAction,
  SubscriptionPlanSelected,
  AcknowledgedIOSLimitation,
  ValidatedEmail,
  ProfileCompleted,
  FirstLogin,
};
