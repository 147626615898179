import ReactGA4 from 'react-ga4';

const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID ?? '',
    },
    {
      trackingId: process.env.REACT_APP_GOOGLE_ADS_TRACKINGID ?? '',
    },
  ]);
};

const TrackGoogleAnalyticsEvent = (category: string, action: string, label: string) => {
  // console.log('GA event:', category, ':', action, ':', label);
  ReactGA4.event({
    category,
    action,
    label,
  });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
