import React, { ReactElement, useState } from 'react';

import {
  Text,
  Grid,
  GridItem,
  Box,
  Link,
  Input,
  Button,
  Image,
  Wrap,
  Alert,
  AlertDescription,
  CloseButton,
  AlertTitle,
  AlertIcon,
} from '@chakra-ui/react';

import { UnAuthFooterStyles } from './styles';
import logoImg from '@assets/images/lvedLogoWhite.svg';
import facebookLogo from '@assets/images/facebookLogo.svg';
import instagramLogo from '@assets/images/instagramLogo.svg';
import linkedInLogo from '@assets/images/linkedinLogo.svg';
// import MenuLink from '../menu-link/MenuLink';
// import { useNavigate } from 'react-router';

export default function UnAuthFooter(): ReactElement {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertText, setAlertText] = useState<null | string>(null);
  const [isAlertError, setIsAlertError] = useState(false);

  function subscribeButtonAction() {
    if (email.length === 0) return;
    setIsLoading(true);
    fetch(`https://def2eei4si42r4k526g6c64wbe0hhpwi.lambda-url.us-east-1.on.aws/?email=${email}`)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setAlertText(json);
            setIsAlertError(false);
            setEmail('');
          });
        } else {
          setAlertText(
            `We were unable to subscribe ${email} at this time.  Please check that you are connected to the internet and you have provided a valid email address and try again.`
          );
          setIsAlertError(true);
        }
      })
      .catch((err) => {
        setAlertText(`We were unable to subscribe ${email} at this time.  Error: ${err}`);
        setIsAlertError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Grid {...UnAuthFooterStyles.gridMaster}>
      {alertText && (
        <GridItem {...UnAuthFooterStyles.gridAlert}>
          <Alert status={isAlertError ? 'error' : 'success'}>
            <AlertIcon />
            <Box>
              <AlertTitle>Subscription {isAlertError ? 'failed.' : 'succeeded!'}</AlertTitle>
              <AlertDescription>{alertText}</AlertDescription>
            </Box>
            <CloseButton
              alignSelf='flex-start'
              position='relative'
              right={-1}
              top={-1}
              onClick={() => {
                setAlertText(null);
              }}
            />
          </Alert>
        </GridItem>
      )}
      <GridItem {...UnAuthFooterStyles.gridItemDouble}>
        <Box {...UnAuthFooterStyles.aboutLvedContainer}>
          <Image {...UnAuthFooterStyles.logo} src={logoImg} />
          <Text {...UnAuthFooterStyles.description}>
            LVED (pronounced loved) is an easy to use expert guided platform that provides
            everything families need to plan, organize, legally document end of life wishes, and
            memorialize loved ones.
          </Text>
          <Box {...UnAuthFooterStyles.socialLogosContainer}>
            <Link
              {...UnAuthFooterStyles.socialLogoContainer}
              href='https://www.facebook.com/lvedcom'
            >
              <Image {...UnAuthFooterStyles.socialLogo} src={facebookLogo} />
            </Link>
            <Link
              {...UnAuthFooterStyles.socialLogoContainer}
              href='https://www.linkedin.com/company/lvedcom'
            >
              <Image {...UnAuthFooterStyles.socialLogo} src={linkedInLogo} />
            </Link>
            <Link
              {...UnAuthFooterStyles.socialLogoContainer}
              href='https://www.instagram.com/lvedcom'
            >
              <Image {...UnAuthFooterStyles.socialLogo} src={instagramLogo} />
            </Link>
          </Box>
        </Box>
      </GridItem>
      <GridItem {...UnAuthFooterStyles.gridItemSingle}>
        <Box {...UnAuthFooterStyles.pageLinksParentContainer}>
          <Text {...UnAuthFooterStyles.sectionTitle}>Product</Text>
          <Box {...UnAuthFooterStyles.pageLinksChildContainer}>
            <Link {...UnAuthFooterStyles.pageLink} href='http://www.lved.com/about-us.html'>
              About us
            </Link>
            <Link {...UnAuthFooterStyles.pageLink} href='http://www.lved.com/prices.html'>
              Prices
            </Link>
            <Link {...UnAuthFooterStyles.pageLink} href='http://www.lved.com/contact-us.html'>
              Contact us
            </Link>
            <Link {...UnAuthFooterStyles.pageLink} href='/articles'>
              Blog
            </Link>
            <Link {...UnAuthFooterStyles.pageLink} href='/faq'>
              FAQ
            </Link>
          </Box>
        </Box>
      </GridItem>
      <GridItem {...UnAuthFooterStyles.gridItemDouble}>
        <Box {...UnAuthFooterStyles.newsLetterContainer}>
          <Text {...UnAuthFooterStyles.sectionTitle}>Subscribe to our newsletter</Text>
          <Wrap spacing='24px'>
            <Input
              {...UnAuthFooterStyles.textInputContainer}
              placeholder='Enter your email'
              value={email}
              onChange={(newValue) => {
                setEmail(newValue.currentTarget.value);
              }}
            />
            <Button
              {...UnAuthFooterStyles.button}
              isLoading={isLoading}
              onClick={() => {
                if (email.length >= 5) subscribeButtonAction();
                else {
                  setAlertText(`${email} is not a proper email address`);
                  setIsAlertError(true);
                }
              }}
            >
              Subscribe
            </Button>
          </Wrap>
        </Box>
      </GridItem>
    </Grid>
  );
}
