import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button, Flex, Image, VStack } from '@chakra-ui/react';

import { useAuth, useQuery } from '@modules/core/hooks';
import { LogoIcon } from '@modules/common-ui/icons';
import { SideBarList } from '@modules/common-ui/components/side-bar/SideBarList';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import {
  ISideBarProps,
  SideBarLocationType,
} from '@modules/common-ui/components/side-bar/interfaces';

import closeMenuImg from '@assets/images/closeMenu.svg';
import hamburgerMenuImg from '@assets/images/hamburgerMenu.svg';
import { sideBarStyles } from '@modules/common-ui/components/side-bar/styles';

import { APP_ROUTES, WITHOUT_SIDEBAR_ROUTES } from '@modules/core/constants';
import { SIDE_BAR_ITEMS } from '@modules/common-ui/components/side-bar/side-bar-items';

// import appStoreDownloadIcon from '../../../../assets/images/downloadFromAppStore.svg';

function SideBar({ isExpanded, toggleNavMenuVisibility }: ISideBarProps): ReactElement {
  const { signOut }: IAuthContext = useAuth();
  const { pathname } = useLocation<SideBarLocationType>();
  const { submit: submitSignOut, isLoading } = useQuery<void, void>(signOut);
  // const [isExpanded, setExpanded] = useState(window.innerWidth > 500);
  /*
{!isExpanded && (
  <VStack {...sideBarStyles.sideBarContainer}>
    <Text>
      This site is in development and should only be used on a desktop computer at this
      time. For full functionality, please use our IOS App.
    </Text>
    <a href='https://apps.apple.com/us/app/lved/id6448995985'>
      <img src={appStoreDownloadIcon} alt='Download from the iOS AppStore' />
    </a>
    <Button
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      Continue anyway
    </Button>
  </VStack>
)}
*/
  const signOutButtonHandler = (): Promise<void> => submitSignOut();

  function includeSideBar(): boolean {
    if ((pathname as String).startsWith(APP_ROUTES.affiliageLandingPageBase)) return false;
    return !WITHOUT_SIDEBAR_ROUTES.includes(pathname);
  }

  return (
    <ConditionSeparator
      condition={includeSideBar() && isExpanded}
      target={
        <Box {...sideBarStyles.sideBarContainer}>
          <VStack {...sideBarStyles.contentBox}>
            <Image
              {...sideBarStyles.closeMenuImage}
              src={closeMenuImg}
              onClick={() => toggleNavMenuVisibility(true)}
            />
            <VStack {...sideBarStyles.logoListContainer}>
              <Flex {...sideBarStyles.iconsFlexBox}>
                <LogoIcon {...sideBarStyles.logoIcon} />
              </Flex>
              <SideBarList
                items={SIDE_BAR_ITEMS}
                toggleNavMenuVisibility={toggleNavMenuVisibility}
              />
            </VStack>
            <Button
              isLoading={isLoading}
              onClick={signOutButtonHandler}
              {...sideBarStyles.signOutButton}
            >
              Sign Out
            </Button>
          </VStack>
        </Box>
      }
      defaultTarget={
        <div>
          {includeSideBar() && (
            <VStack {...sideBarStyles.sideBarContainerCollapsed}>
              <Image
                {...sideBarStyles.hamburgerMenuImage}
                src={hamburgerMenuImg}
                onClick={() => toggleNavMenuVisibility(true)}
              />
            </VStack>
          )}
        </div>
      }
    />
  );
}

export default SideBar;
