import React, { useState, useEffect, ReactElement } from 'react';

import { appEntryParamsContext as AppEntryParamsContext } from './appEntryParams-context';
import { IAppEntryParamsContextProviderProps } from './interfaces';

function AppEntryParamsContextProvider(props: IAppEntryParamsContextProviderProps): ReactElement {
  const [affiliateCode, setAffiliateCode] = useState<undefined | string>();
  const [promoCode, setPromoCode] = useState<undefined | string>();

  const setAppEntryParamsFromURL = (url: string): void => {
    const params = new URLSearchParams(url);
    if (params.has('affiliate')) {
      const code = params.get('affiliate') ?? undefined;
      setAffiliateCode(code);
    }
    if (params.has('promoCode')) {
      const code = params.get('promoCode') ?? undefined;
      setPromoCode(code);
    }
  };

  useEffect(() => {
    setAppEntryParamsFromURL(props.entryPointURL);
  }, []);

  return (
    <AppEntryParamsContext.Provider
      value={{
        affiliateCode,
        promoCode,
        setAppEntryParamsFromURL,
      }}
    >
      {props.children}
    </AppEntryParamsContext.Provider>
  );
}

export default AppEntryParamsContextProvider;
