import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import DashBoard from './pages/dashboard';
import EmailVerification from './pages/email-verification';
import Pricing from './pages/pricing';
import OnBoarding from './pages/onboarding';
import Welcome from './pages/welcome';
import Payment from './pages/payment';
import PaymentCompletion from './pages/payment-completion';
import SubscriptionManagement from './pages/subscription-management';
import FamilyHome from './pages/family-registration';
import BrowseStories from './pages/browse-stories';
import FinalMessage from './pages/view-final-message';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import Profile from './pages/profile';
import Articles from './pages/articles';
import FAQs from './pages/faqs';
import AffiliateLandingPage from './pages/affiliate-landing-pages/AffiliateLandingPage';
import profileOnboarding, { PostSubscriptionGuide } from './pages/profile-onboarding';

import { RouteType } from './modules/core/routing/interfaces';
import {
  DigitalVault,
  DigitalVaultLayout,
  DigitalVaultSection,
  DigitalVaultAsests,
  DigitalVaultSectionLayout,
} from './pages/digital-vault';

import { APP_ROUTES, HIERARCHY_SECTION } from './modules/core/constants';

const routes: RouteType[] = [
  {
    path: APP_ROUTES.root,
    redirect: APP_ROUTES.dashboard,
  },
  {
    path: APP_ROUTES.signIn,
    component: SignIn,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.signUp,
    component: SignUp,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.resetPassword,
    component: ForgotPassword,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.emailVerification,
    component: EmailVerification,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.dashboard,
    component: DashBoard,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: true,
  },
  {
    path: APP_ROUTES.pricing,
    component: Pricing,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.onboarding,
    component: OnBoarding,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: false,
  },
  {
    path: APP_ROUTES.welcome,
    component: Welcome,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: false,
  },
  {
    path: APP_ROUTES.profileOnBoarding,
    component: profileOnboarding,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: false,
  },
  {
    path: APP_ROUTES.appDownloadOnboarding,
    component: PostSubscriptionGuide,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: true,
  },
  {
    path: APP_ROUTES.payment,
    component: Payment,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.paymentCompletion,
    component: PaymentCompletion,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.subscriptionManagement,
    component: SubscriptionManagement,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.profile,
    component: Profile,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.digitalVault,
    component: DigitalVaultLayout,
    children: [
      {
        component: DigitalVault,
        parentPath: APP_ROUTES.digitalVault,
        isAuthProtected: true,
        index: true,
        isNeedToBeWrapped: false,
      },
      {
        path: APP_ROUTES.digitalVaultFamilyDocuments,
        component: DigitalVaultSectionLayout,
        isNeedToBeWrapped: false,
        props: { section: HIERARCHY_SECTION.familyDocuments },
        children: [
          {
            index: true,
            component: DigitalVaultSection,
            parentPath: APP_ROUTES.digitalVaultFamilyDocuments,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
          {
            path: APP_ROUTES.digitalVaultFamilyDocumentsAssets,
            component: DigitalVaultAsests,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
        ],
      },
      {
        path: APP_ROUTES.digitalVaultOtherDocuments,
        component: DigitalVaultSectionLayout,
        isNeedToBeWrapped: false,
        props: { section: HIERARCHY_SECTION.otherDocuments },
        children: [
          {
            index: true,
            component: DigitalVaultSection,
            parentPath: APP_ROUTES.digitalVaultOtherDocuments,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
          {
            path: APP_ROUTES.digitalVaultOtherDocumentsAssets,
            component: DigitalVaultAsests,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
        ],
      },
      {
        path: APP_ROUTES.digitalVaultPersonalDocuments,
        component: DigitalVaultSectionLayout,
        isNeedToBeWrapped: false,
        props: { section: HIERARCHY_SECTION.personalDocuments },
        children: [
          {
            index: true,
            component: DigitalVaultSection,
            parentPath: APP_ROUTES.digitalVaultPersonalDocuments,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
          {
            path: APP_ROUTES.digitalVaultPersonalDocumentsAssets,
            component: DigitalVaultAsests,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
        ],
      },
      {
        path: APP_ROUTES.digitalVaultPasswordsCombinations,
        component: DigitalVaultSectionLayout,
        isNeedToBeWrapped: false,
        props: { section: HIERARCHY_SECTION.passwords },
        children: [
          {
            index: true,
            component: DigitalVaultSection,
            parentPath: APP_ROUTES.digitalVaultPasswordsCombinations,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
          {
            path: APP_ROUTES.digitalVaultPasswordsCombinationsAssets,
            component: DigitalVaultAsests,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
        ],
      },
      {
        path: APP_ROUTES.digitalVaultTaxes,
        component: DigitalVaultSectionLayout,
        isNeedToBeWrapped: false,
        props: { section: HIERARCHY_SECTION.taxes },
        children: [
          {
            index: true,
            component: DigitalVaultSection,
            parentPath: APP_ROUTES.digitalVaultTaxes,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
          {
            path: APP_ROUTES.digitalVaultTaxesAssets,
            component: DigitalVaultAsests,
            isAuthProtected: true,
            isNeedToBeWrapped: false,
          },
        ],
      },
    ],
  },
  {
    path: APP_ROUTES.watchStories,
    component: BrowseStories,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.familyNotification,
    component: FamilyHome,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.affiliateLandingPages,
    component: AffiliateLandingPage,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.finalMessage,
    component: FinalMessage,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.articleNavigation,
    component: Articles,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.faqNavigation,
    component: FAQs,
    isAuthProtected: false,
  },
  {
    path: APP_ROUTES.isNotExist,
    redirect: APP_ROUTES.dashboard,
  },
];

export default routes;
