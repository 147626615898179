import React, { useState, ChangeEvent, ReactElement } from 'react';

import { Text, Flex, Heading, VStack, HStack } from '@chakra-ui/react';

import { IQuestionBasicProps } from './interfaces';
import { TextInput } from '../text-field';

import { questionStyles } from './styles';

type address = {
  City: string;
  Country: string;
  State: string;
  Street1: string;
  Street2: string;
  Zip: string;
};

function AddressQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: address | undefined = answers[question.FieldName];
  const [values, setValues] = useState<address>(
    oldAnswer || { City: '', Country: '', State: '', Street1: '', Street2: '', Zip: '' }
  );
  const [error] = useState<string>('');

  /* const isCompleteAddress = (): boolean => {
    return (
      values.Street1?.length >= 2 &&
      values.City?.length >= 1 &&
      values.State?.length >= 2 &&
      values.Country?.length >= 2 &&
      values.Zip?.length >= 5
    );
  }; */

  return (
    <Flex {...questionStyles.questionContainer} margin='10px'>
      <VStack {...questionStyles.questionTextBox} padding='0px 0px 20px 0px'>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <VStack w='100%'>
        <TextInput
          size='md'
          label='Street1'
          value={values.Street1}
          id='Street1'
          name='Street1'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, Street1: e.target.value });
            callback(question.FieldName, { ...values, Street1: e.target.value });
          }}
        />

        <TextInput
          size='md'
          label='Street2'
          value={values.Street2}
          id='Street2'
          name='Street2'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, Street2: e.target.value });
            callback(question.FieldName, { ...values, Street2: e.target.value });
          }}
        />
      </VStack>

      <HStack>
        <TextInput
          size='md'
          label='City'
          value={values.City}
          id='City'
          name='City'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, City: e.target.value });
            callback(question.FieldName, { ...values, City: e.target.value });
          }}
        />

        <TextInput
          size='md'
          label='State'
          value={values.State}
          id='State'
          name='State'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, State: e.target.value });
            callback(question.FieldName, { ...values, State: e.target.value });
          }}
        />

        <TextInput
          size='md'
          label='Zip'
          value={values.Zip}
          id='Zip'
          name='Zip'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, Zip: e.target.value });
            callback(question.FieldName, { ...values, Zip: e.target.value });
          }}
        />
      </HStack>
      <TextInput
        size='md'
        label='Country'
        value={values.Country}
        id='Country'
        name='Country'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValues({ ...values, Country: e.target.value });
          callback(question.FieldName, { ...values, Country: e.target.value });
        }}
      />

      {error && <Text {...questionStyles.errorText}>{error}</Text>}
    </Flex>
  );
}

export default AddressQuestion;
